'use client';

import { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { format, isSameDay } from 'date-fns';
import { CircleIcon, PlayCircleIcon, cn } from '@inderes/videosync-ui';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

import { trpc } from '@/app/_trpc/client';
import { EventState } from '@inderes/videosync-types';
import { useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { focusNextElement, linkHelper } from '@/app/(library-site)/utils';

const LibraryPlayer = dynamic(
  () => import('@/app/(library-site)/components/library-bitmovin').then((mod) => mod.LibraryPlayer),
  {
    ssr: false,
    loading: () => (
      <Image
        className="w-full"
        width={1568}
        height={882}
        src="/images/player-placeholder.svg"
        alt="Player placeholder"
      />
    ),
  },
);

const Skeleton = () => (
  <div className="bg-accent flex animate-pulse flex-col items-center justify-between gap-6 border-b px-5 pb-8 pt-0 lg:flex-row lg:gap-16 lg:p-12 lg:pb-12">
    <div className="bg-card mx-12 my-4 aspect-video flex-1" />
    {/* Dummy skeleton text area */}
    <div className="flex flex-1 flex-col gap-4 p-4">
      <div className="bg-card h-5 w-1/4" />
      <div className="bg-card h-10 w-full" />
      <div className="bg-card h-4 w-full" />
      <div className="bg-card h-4 w-full" />
      <div className="bg-card h-4 w-full" />
      <div className="bg-card h-4 w-full" />
      <div className="bg-card mt-4 h-5 w-full" />
    </div>
  </div>
);

export const EventBanner = ({ accountSlug, categoryId }: { accountSlug: string; categoryId?: string }) => {
  const t = useI18n();
  const locale = useCurrentLocale();
  const pathname = usePathname();
  const getEvent = trpc.library.getFeaturedEvent.useQuery({ accountSlug, categoryId }, { refetchOnWindowFocus: false });
  const eventData = getEvent.data;

  const [showPlayer, setShowPlayer] = useState(false);

  const isPlayable = eventData && [EventState.LIVE, EventState.PUBLISH_RECORDING].includes(eventData.state);

  if (!eventData) return <Skeleton />;

  const ImageWithPlay = ({ iconClassName }: { iconClassName?: string }) => (
    <>
      <Image
        width={720}
        height={405}
        src={`${
          eventData?.previewImg ||
          (['vn', 'valtioneuvosto'].includes(accountSlug)
            ? 'https://cdn.videosync.fi/events/vnk/common/images/vnk-fasadi.jpeg'
            : 'https://cdn.videosync.fi/assets/admin/img/thumb.png')
        }`}
        alt={t('preview.img.aria', { title: eventData?.title })}
        className="w-full"
      />
      <PlayCircleIcon
        className={cn(
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white transition',
          iconClassName,
        )}
        aria-hidden
      />
    </>
  );

  const { linkHref } = linkHelper({ pathname, accountSlug, href: `/v/${eventData.urlName}`, locale });
  const isUpcoming = new Date(eventData.publishingDate) > new Date() && eventData.state === EventState.SHARE;
  const isToday = isSameDay(new Date(eventData.publishingDate), new Date());
  const title = (locale === 'sv' && eventData?.translation?.sv?.title) || eventData.title || '';
  const description =
    (locale === 'sv' && eventData?.translation?.sv?.about?.description) || eventData.about?.description || '';

  return (
    <div className="bg-accent flex items-center justify-center px-5 pb-8 pt-0 lg:gap-16 lg:p-12 lg:pb-12">
      <div className="flex max-w-[100rem] flex-1 flex-col items-center justify-between gap-6 lg:flex-row">
        {showPlayer ? (
          <div className="relative flex-1">
            <button
              className="absolute -top-8 left-0 h-0 overflow-hidden opacity-0 focus-visible:overflow-visible focus-visible:opacity-100"
              onClick={focusNextElement}
            >
              {t('skip.player')}
            </button>
            <LibraryPlayer eventData={eventData} accountSlug={accountSlug} />
          </div>
        ) : isPlayable ? (
          <button className="group relative flex flex-1" onClick={() => setShowPlayer((val) => !val)}>
            <ImageWithPlay iconClassName="group-hover:scale-110" />
          </button>
        ) : (
          <Link href={linkHref} className="group relative flex flex-1" prefetch={false}>
            <ImageWithPlay iconClassName="group-hover:scale-110" />
          </Link>
        )}
        <div className="flex flex-1 flex-col">
          <p className="text-muted text-sm">
            {eventData.state === EventState.LIVE ? (
              <span className="text-primary flex items-center gap-2 text-lg font-semibold">
                <CircleIcon aria-hidden />
                <span>Live</span>
              </span>
            ) : isUpcoming && isToday ? (
              <span className="text-primary font-semibold">
                {t('upcoming.today', { time: format(eventData.publishingDate, 'HH:mm') })}
              </span>
            ) : (
              <span>
                {isUpcoming && eventData.state === EventState.SHARE && (
                  <span className="text-muted">{t('upcoming')} </span>
                )}
                {format(eventData.publishingDate, 'dd.MM.yyyy - HH:mm')}
              </span>
            )}
          </p>
          {title && (
            <Link href={linkHref} prefetch={false} className="group">
              <h2 className="font-condensed mt-4 max-w-xl text-2xl font-semibold group-hover:underline sm:text-3xl lg:text-4xl">
                {title}
              </h2>
            </Link>
          )}
          {description && (
            <p className="text-muted-foreground white mt-4 line-clamp-4 max-w-xl whitespace-pre-wrap lg:mt-9">
              {description}
            </p>
          )}
          {eventData.tags?.length > 0 && (
            <div className="mt-7 flex flex-wrap items-center gap-2.5 lg:mt-11">
              {/* TODO: Fix type */}
              {eventData.tags.map((tag: any) => {
                const tagLabel = tag?.[locale];
                const { linkHref } = linkHelper({ pathname, accountSlug, href: `/search?keyword=${tagLabel}`, locale });
                return (
                  <Link
                    href={linkHref}
                    className="text-primary-alt bg-primary-foreground hover:bg-primary-alt hover:text-primary-foreground rounded px-3 py-2 text-xs font-semibold transition"
                    key={tag.id}
                    prefetch={false}
                  >
                    {tagLabel}
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
