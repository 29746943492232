'use client';

import { ChevronRightIcon, ChevronLeftIcon, cn } from '@inderes/videosync-ui';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

import { useI18n } from '@/app/(library-site)/locales/client';

export const Pagination = ({ totalPages, currentPage }: { totalPages: number; currentPage: number }) => {
  const searchParams = useSearchParams();
  const t = useI18n();

  /**
   * Get the current query as an object with added page parameter
   */
  const getQueryWithPage = useCallback(
    (page: number) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set('page', page.toString());

      // get the key value pairs from the search params
      const paramsObject: Record<string, string> = {};
      for (const [key, value] of params.entries()) {
        paramsObject[key] = value;
      }
      return paramsObject;
    },
    [searchParams],
  );

  return (
    <div className="mt-8 flex items-center justify-center gap-2">
      {/* Previous page */}
      <Link
        className={cn(
          'text-primary mr-1 flex h-4 w-5 items-center justify-center sm:mr-5 sm:h-10 sm:w-12',
          currentPage === 1 && 'pointer-events-none opacity-20',
        )}
        href={{
          query: getQueryWithPage(currentPage - 1),
        }}
        aria-label={t('page.previous')}
        prefetch={false}
      >
        <ChevronLeftIcon aria-hidden />
      </Link>

      {/* Page numbers */}
      {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
        <Link
          key={page}
          className={cn(
            'text-primary flex h-8 w-8 items-center justify-center rounded-full sm:h-10 sm:w-10',
            currentPage === page && 'bg-card',
          )}
          href={{
            query: getQueryWithPage(page),
          }}
          aria-label={t('page.go.to', { page })}
          prefetch={false}
        >
          {page}
        </Link>
      ))}

      {/* Next page */}
      <Link
        className={cn(
          'text-primary ml-1 flex h-4 w-5 items-center justify-center sm:ml-5 sm:h-10 sm:w-12',
          currentPage === totalPages && 'pointer-events-none opacity-20',
        )}
        href={{
          query: getQueryWithPage(currentPage + 1),
        }}
        aria-label={t('page.next')}
        prefetch={false}
      >
        <ChevronRightIcon aria-hidden />
      </Link>
    </div>
  );
};
