'use client';

import { trpc } from '@/app/_trpc/client';
import { Carousel, CarouselContent, CarouselItem } from '@inderes/videosync-ui';
import Link from 'next/link';
import { ArrowRightIcon } from '@inderes/videosync-ui';
import { usePathname } from 'next/navigation';

import { CategoryItem } from './category-item';
import { useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { linkHelper } from '@/app/(library-site)/utils';

const Skeleton = () => (
  <div className="flex w-full animate-pulse flex-wrap justify-center gap-8">
    {[...Array(4)].map((_, i) => (
      <div
        key={i}
        className="bg-card flex aspect-video max-w-[720px] basis-full flex-col lg:basis-[calc(25%-1.5rem)]"
      />
    ))}
  </div>
);

export const CategoryEvents = ({
  accountSlug,
  category,
  numberOfEvents,
  title,
  showAllEventsLink,
  showSubCategoryEvents,
}: {
  accountSlug: string;
  category?: {
    id: string;
    name: string;
    slug: string;
  };
  numberOfEvents?: number;
  title?: string;
  showAllEventsLink?: boolean;
  showSubCategoryEvents?: boolean;
}) => {
  const t = useI18n();
  const locale = useCurrentLocale();
  const pathname = usePathname();
  const getEvents = trpc.library.getCategoryEvents.useQuery(
    {
      categoryId: category?.id || '',
      limit: numberOfEvents || 8,
      getSubcategoryEvents: showSubCategoryEvents,
    },
    {
      retry(failureCount, error) {
        if (error.data?.httpStatus === 404 || error.data?.httpStatus === 400) {
          return false;
        }
        return failureCount < 2;
      },
    },
  );
  const { linkHref } = linkHelper({ pathname, accountSlug, href: `/${category?.slug || ''}`, locale });
  return (
    // TODO: Checkkaa onks tää :last-of-type OK muualla kuin etusivulla -> taitaa olla toistaiseksi
    <div className="flex items-center justify-center p-5 pb-2 pt-8 last-of-type:pb-16 lg:p-12 lg:pb-4">
      <div className="flex w-full max-w-[100rem] flex-1 flex-col gap-6">
        <div className="flex items-center justify-between gap-4">
          <h3 className="font-condensed text-xl font-semibold sm:text-2xl md:text-3xl">
            {title || category?.name?.split(' / ')?.slice(-1) || 'Category title'}
          </h3>
          {showAllEventsLink && (
            <Link
              href={linkHref}
              className="text-primary-alt flex items-center gap-3 whitespace-nowrap font-semibold hover:underline"
              prefetch={false}
            >
              {t('see.all')} <ArrowRightIcon aria-hidden />
            </Link>
          )}
        </div>
        {getEvents.isLoading && <Skeleton />}
        {getEvents && (
          <>
            {/* Carousel for mobile */}
            <Carousel
              className="lg:hidden"
              opts={{
                skipSnaps: true,
              }}
            >
              <CarouselContent>
                {getEvents.data?.events?.map((event) => (
                  <CarouselItem key={event.id} className="basis-2/3">
                    <CategoryItem event={event} accountSlug={accountSlug} />
                  </CarouselItem>
                ))}
              </CarouselContent>
            </Carousel>
            {/* Regular grid for desktop */}
            <div className="hidden flex-wrap gap-8 lg:flex">
              {getEvents.data?.events?.map((event) => (
                /* Flex basis is calculated by 25% of the container width minus the gap between the items:
        3 * 2rem / 4 = 1.5rem (3 x 2rem gaps per 4 items) */
                <div className="flex basis-[calc(25%-1.5rem)] flex-col" key={event.id}>
                  <CategoryItem event={event} accountSlug={accountSlug} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
