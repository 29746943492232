import { z } from 'zod';

export const turboBridgeConferenceInfoCall = z.object({
  callID: z.string(),
  name: z.string(),
  fromNumber: z.string(),
  fromName: z.string(),
  host: z.number(),
  status: z.string(),
  hold: z.number(),
  muted: z.number(),
  handRaised: z.number(),
  userID: z.string(),
  callerID: z.string(),
  subConf: z.string(),
});

export const turboBridgeConferenceBridgeUserId = z.object({
  name: z.string(),

  userID: z.string(),
});
export const turboBridgeConferenceInfoCallList = z.array(turboBridgeConferenceInfoCall);
export const turboBridgeConferenceBridgeUserIdList = z.array(turboBridgeConferenceBridgeUserId);

export const turboBridgeConfig = z.object({
  baseUrl: z.string(),
  email: z.string(),
  password: z.string(),
  partnerId: z.string(),
  accountId: z.string(),
});
export const postToLCM = z.object({
  changeConferenceCall: z
    .object({
      conferenceID: z.number(),
      callID: z.string(),
      command: z.string(),
      value: z.string(),
    })
    .optional(),
  changeConference: z
    .object({
      conferenceID: z.number(),
      command: z.string(),
      value: z.string(),
    })
    .optional(),
  makeCall: z
    .object({
      name: z.string(),
      conferenceID: z.number(),
      userID: z.string(),
      muted: z.number(),
      excludeFromCount: z.number(),
      number: z.string().optional(),
      ext: z.string().optional(),
      provider: z.string().optional(),
      subConferenceID: z.string().optional(),
      host: z.number().optional(),
      providerParams: z
        .array(
          z.object({
            name: z.string(),
            value: z.string(),
          }),
        )
        .optional(),
    })
    .optional(),
});
export const getFromLCM = z.object({
  getConferenceInfo: z
    .object({
      conferenceID: z.number(),
    })
    .optional(),
});

export const postToBridge = z.object({
  setBridge: z
    .object({
      conferenceID: z.number(),
      confStart: z.string(),

      minParticipants: z.number().optional(),
      pin: z.string().optional(),
      name: z.string().optional(),
      confMode: z.string().optional(),
      confEnd: z.number().optional(),
      entryChimes: z.string().optional(),
      exitChimes: z.string().optional(),
      timezone: z.string().optional(),
      storeSelectedField: z.string().optional(),
      storeHandRaisedField: z.string().optional(),
      customSetting: z.string().optional(),
    })
    .optional(),
  setBridgeUserID: z
    .object({
      conferenceID: z.number(),
      userID: z.string(),
      name: z.string().optional(),
    })
    .optional(),
});

export type TurboBridgeConferenceInfoCall = z.infer<typeof turboBridgeConferenceInfoCall>;
export type TurboBridgeConferenceInfoCallList = z.infer<typeof turboBridgeConferenceInfoCallList>;
export type TurboBridgeConfig = z.infer<typeof turboBridgeConfig>;
export type TurboBridgeConferenceBridgeUserIdList = z.infer<typeof turboBridgeConferenceBridgeUserIdList>;

export type postToLCMType = z.infer<typeof postToLCM>;
export type postToBridgeType = z.infer<typeof postToBridge>;

export type getFromLCMType = z.infer<typeof getFromLCM>;
