'use client';

import { useEffect } from 'react';

import { trpc } from '@/app/_trpc/client';
import { Pagination } from '../category-all-events/pagination';
import { CategoryItem } from '../category-events/category-item';
import { usePageFilter, useYearFilter } from '@/app/_hooks/search-params';
import { useI18n } from '@/app/(library-site)/locales/client';

const Skeleton = () => (
  <div className="flex w-full animate-pulse flex-wrap justify-center gap-8">
    {[...Array(4)].map((_, i) => (
      <div
        key={i}
        className="bg-card flex aspect-video max-w-[720px] basis-full flex-col lg:basis-[calc(25%-1.5rem)]"
      />
    ))}
  </div>
);

export const SearchEvents = ({
  accountSlug,
  numberOfEventsPerPage,
  keyword,
  countText,
  setCountText,
}: {
  accountSlug: string;
  keyword: string;
  numberOfEventsPerPage: number;
  countText: number;
  setCountText: (count: number) => void;
}) => {
  const t = useI18n();
  const numOfEventsPerPage = typeof window !== 'undefined' && window.innerWidth <= 1024 ? 5 : numberOfEventsPerPage;
  const yearFilter = useYearFilter();
  const pageNum = usePageFilter();

  const searchEvents = trpc.library.searchEventsFromSearchableCategories.useQuery(
    {
      accountSlug,
      limit: numOfEventsPerPage,
      page: pageNum,
      year: yearFilter === 'all' ? undefined : parseInt(yearFilter),
      keyword,
    },
    {
      retry(failureCount, error) {
        if (error.data?.httpStatus === 404 || error.data?.httpStatus === 400) {
          return false;
        }
        return failureCount < 2;
      },
    },
  );
  const totalPages = Math.ceil((searchEvents.data?.total || 0) / numOfEventsPerPage);

  // Update countText when the total count changes, so that the header shows the correct count
  useEffect(() => {
    if (countText !== searchEvents?.data?.total) {
      setCountText(searchEvents.data?.total || 0);
    }
  }, [searchEvents?.data?.total, countText, setCountText]);

  if (searchEvents.isLoading) {
    return <Skeleton />;
  }

  return (
    <div>
      <div className="flex flex-col items-center gap-8 lg:flex-row lg:flex-wrap lg:items-stretch">
        {searchEvents.data?.events?.length === 0 && (
          <div className="flex min-h-[200px] w-full items-center justify-center">
            <p className="text-lg">{t('list.no.results')}</p>
          </div>
        )}
        {searchEvents.data?.events?.map((event) => (
          /* Flex basis is calculated by 25% of the container width minus the gap between the items:
        3 * 2rem / 4 = 1.5rem (3 x 2rem gaps per 4 items) */
          <div className="flex flex-col lg:basis-[calc(25%-1.5rem)]" key={event.id}>
            <CategoryItem event={event} accountSlug={accountSlug} />
          </div>
        ))}
      </div>
      {totalPages > 1 && (
        <div className="mt-8 flex items-center justify-center gap-2">
          <Pagination totalPages={totalPages} currentPage={pageNum} />
        </div>
      )}
    </div>
  );
};
