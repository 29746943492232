'use client';

import { cn, Button, Input, Label, SearchAltIcon } from '@inderes/videosync-ui';

import { useState } from 'react';
import { useRouter, usePathname, useSearchParams } from 'next/navigation';

import { useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { linkHelper } from '@/app/(library-site)/utils';

export const SearchInput = ({
  accountSlug,
  label,
  placeholder,
  initialKeyword,
  className,
  endpoint,
}: {
  accountSlug: string;
  label?: string;
  placeholder?: string;
  initialKeyword?: string;
  className?: string;
  endpoint?: string;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const t = useI18n();
  const locale = useCurrentLocale();

  const forId = endpoint ? 'search-endpoint' : 'search';

  const [searchKeyword, setSearchKeyword] = useState(initialKeyword || '');

  const goToSearchPage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (searchKeyword.length > 2) {
      const params = new URLSearchParams(searchParams);
      params.set('keyword', searchKeyword);

      if (endpoint) {
        const searchHref = `${endpoint}?${params.toString()}`;
        const { linkHref: searchURL } = linkHelper({ pathname, href: searchHref, locale, accountSlug });
        return router.push(searchURL);
      }
      // Use the current page url and just add the keyword (current url + keyword)
      const searchHref = `${pathname}?${params.toString()}`;

      return router.push(searchHref);
    }
  };
  return (
    <form
      className={cn(
        'bg-primary-foreground ring-primary relative flex rounded p-1.5 focus-within:ring-2 sm:min-w-[15rem]',
        className,
      )}
      onSubmit={goToSearchPage}
    >
      <Label htmlFor={forId} className="absolute left-3 top-2 text-sm font-semibold">
        {label || t('search')}
      </Label>
      <Input
        id={forId}
        value={searchKeyword}
        onChange={(e) => setSearchKeyword(e.target.value)}
        placeholder={placeholder || t('search.placeholder')}
        className="bg-primary-foreground border-none pl-1.5 pt-6 focus-visible:ring-0"
        autoComplete="off"
        name="keyword"
      />
      <Button type="submit" className="bg-primary-alt text-primary-foreground px-3" aria-label={t('search')}>
        <SearchAltIcon />
      </Button>
    </form>
  );
};
