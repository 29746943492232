/* eslint-disable @next/next/no-img-element */
'use client'

import Link from 'next/link';
import { format, isSameDay } from 'date-fns';
import { CircleIcon, PlayCircleIcon } from '@inderes/videosync-ui';
import { EventState } from '@inderes/videosync-types';
import { usePathname } from 'next/navigation';

import { RouterOutputs } from '@/app/_trpc/client';
import { useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { linkHelper } from '@/app/(library-site)/utils';

export const CategoryItem = ({
  event,
  accountSlug,
}: {
  event: RouterOutputs['library']['getCategoryEvents']['events'][0];
  accountSlug: string;
}) => {
  const t = useI18n();
  const locale = useCurrentLocale();
  const pathname = usePathname();
  const { linkHref } = linkHelper({ pathname, href: `/v/${event.urlName}`, locale, accountSlug });
  const isUpcoming = new Date(event.publishingDate) > new Date() && event.state === EventState.SHARE;
  const isToday = isSameDay(new Date(event.publishingDate), new Date());
  const title = (locale === 'sv' && event?.translation?.sv?.title) || event.title || '';

  return (
    <>
      <Link href={linkHref} className="group" prefetch={false}>
        <div className="flex flex-col gap-4 pb-4">
          <div className="relative">
            <img
              src={event?.previewImg ||
                (['vn', 'valtioneuvosto'].includes(accountSlug)
                  ? 'https://cdn.videosync.fi/events/vnk/common/images/vnk-fasadi.jpeg'
                  : 'https://cdn.videosync.fi/assets/admin/img/thumb.png')}
              alt={t('preview.img.aria', { title: event.title })}
            />
            <PlayCircleIcon
              className="absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 text-white transition group-hover:scale-110"
              aria-hidden
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-muted flex items-center justify-between text-sm">
              {event.state === EventState.SHARE && isUpcoming && isToday ? (
                <span className="text-primary font-semibold">
                  {t('upcoming.today', { time: format(event.publishingDate, 'HH:mm') })}
                </span>
              ) : (
                <span>
                  {isUpcoming && event.state === EventState.SHARE && (
                    <span className="text-muted">{t('upcoming')} </span>
                  )}
                  {format(event.publishingDate, 'dd.MM.yyyy - HH:mm')}
                </span>
              )}
              {event.state === EventState.LIVE && (
                <span className="text-primary flex items-center gap-1.5 font-semibold">
                  <CircleIcon aria-hidden />
                  <span>Live</span>
                </span>
              )}
            </p>
            <h4 className="font-condensed text-xl font-semibold group-hover:underline">{title}</h4>
          </div>
        </div>
      </Link>
      {event.tags?.length > 0 && (
        <div className="mt-auto flex flex-wrap items-center gap-1">
          {event.tags.slice(0, 2)?.map((tag) => {
            const tagLabel = tag?.[locale];
            const { linkHref } = linkHelper({ pathname, accountSlug, href: `/search?keyword=${tagLabel}`, locale });
            return (
              <Link
                href={linkHref}
                className="text-primary bg-card hover:bg-primary-alt hover:text-primary-foreground rounded px-3 py-2 text-xs font-semibold transition"
                key={tag.id}
                prefetch={false}
              >
                {tagLabel}
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};
