'use client';

import { ComponentConfig } from '@measured/puck';
import trpc from '@/app/_trpc/vanillaClient';
import { CategoryEvents } from './category-events';
import { useCurrentLocale } from '@/app/(library-site)/locales/client';

// Left here for reference if using custom field type
// import {
//   Select,
//   SelectContent,
//   SelectGroup,
//   SelectItem,
//   SelectLabel,
//   SelectTrigger,
//   SelectValue,
// } from '@inderes/videosync-ui';

export type CategoryEventsBlockProps = {
  title: string;
  titles?: {
    code: string;
    name: string;
  }[];
  category?: {
    id: string;
    name: string;
    slug: string;
  };
  numberOfEvents?: number;
  showAllEventsLink?: boolean;
  showSubcategoryEvents?: boolean;
};

export const CategoryEventsBlock = (
  accountSlug: string,
  numberOfEvents?: number,
): ComponentConfig<CategoryEventsBlockProps> => {
  const locale = useCurrentLocale();
  return {
    fields: {
      title: { type: 'text', label: 'Title (defaults to category title)' },
      titles: {
        type: 'array',
        label: 'Titles (multilanguage)',
        arrayFields: {
          code: {
            type: 'select',
            label: 'Language',
            options: [
              // TODO: get from database
              { label: 'Finnish', value: 'fi' },
              { label: 'Swedish', value: 'sv' },
            ],
          },
          name: { type: 'text', label: 'Title' },
        },
      },
      numberOfEvents: { type: 'number', label: 'Number of events to show' },
      showAllEventsLink: {
        type: 'radio',
        label: 'Show all events link',
        options: [
          { label: 'On', value: true },
          { label: 'Off', value: false },
        ],
      },
      showSubcategoryEvents: {
        type: 'radio',
        label: 'Show events from subcategories',
        options: [
          { label: 'On', value: true },
          { label: 'Off', value: false },
        ],
      },
      // category: {
      //   type: 'custom',      // category: {
      //   type: 'custom',
      //   label: 'Category',
      //   render: ({ field, value, onChange }) => {
      //     const getCategories = trpc.library.getCategories.useQuery({
      //       accountSlug,
      //     });

      //     return (
      //       <FieldLabel label="Category">
      //           <select value={value || ''} onChange={(e) => onChange(e.target.value)}>
      //             <option value="">Select a category</option>
      //             {getCategories.data?.map((category) => (
      //               <option key={category.id} value={category.id}>
      //                 {category.name}
      //               </option>
      //             ))}
      //           </select>
      //           {/* <Select value={value || ''} onValueChange={(newValue) => onChange(newValue)}>
      //             <SelectTrigger className="w-[180px]">
      //               <SelectValue placeholder="Select a category" />
      //             </SelectTrigger>
      //             <SelectContent>
      //               <SelectGroup>

      //                 {getCategories.data?.map((category) => (
      //                   <SelectItem key={category.id} value={category.id}>
      //                     {category.name}
      //                   </SelectItem>
      //                 ))}
      //               </SelectGroup>
      //             </SelectContent>
      //           </Select> */}
      //       </FieldLabel>
      //     );
      //   },
      // },

      //     return (
      //       <FieldLabel label="Category">
      //           <select value={value || ''} onChange={(e) => onChange(e.target.value)}>
      //             <option value="">Select a category</option>
      //             {getCategories.data?.map((category) => (
      //               <option key={category.id} value={category.id}>
      //                 {category.name}
      //               </option>
      //             ))}
      //           </select>
      //           {/* <Select value={value || ''} onValueChange={(newValue) => onChange(newValue)}>
      //             <SelectTrigger className="w-[180px]">
      //               <SelectValue placeholder="Select a category" />
      //             </SelectTrigger>
      //             <SelectContent>
      //               <SelectGroup>

      //                 {getCategories.data?.map((category) => (
      //                   <SelectItem key={category.id} value={category.id}>
      //                     {category.name}
      //                   </SelectItem>
      //                 ))}
      //               </SelectGroup>
      //             </SelectContent>
      //           </Select> */}
      //       </FieldLabel>
      //     );
      //   },
      // },
      category: {
        type: 'external',
        placeholder: 'Select category',
        fetchList: async () => {
          const categories = await trpc.library.getCategories.query({ accountSlug });
          return categories.map((category) => ({
            name: category.name,
            slug: category.slug,
            id: category.id,
          }));
        },
        label: 'Category',
        getItemSummary: (item) => item?.name || '',
      },
    },
    defaultProps: {
      title: '',
      showAllEventsLink: true,
      showSubcategoryEvents: false,
    },
    render: (
      { title, titles, category, numberOfEvents, showAllEventsLink, showSubcategoryEvents }, // TODO: saadaanko titleen defaulttina category.name?
    ) => {
      const correctTitle = (titles?.find((t) => t.code === locale) || titles?.[0])?.name || title;

      return (
        <CategoryEvents
          title={correctTitle}
          showAllEventsLink={showAllEventsLink}
          accountSlug={accountSlug}
          category={category}
          numberOfEvents={numberOfEvents}
          showSubCategoryEvents={showSubcategoryEvents}
        />
      );
    },
  };
};
