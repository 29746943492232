'use client';

import { ComponentConfig } from '@measured/puck';
import { useState } from 'react';

import { trpc } from '@/app/_trpc/client';
import { SearchEvents } from './search-events';
import { SearchInput } from './search-input';
import { YearSelect } from '@/app/common/components/year-select';
import { useI18n } from '@/app/(library-site)/locales/client';
import { useKeywordFilter } from '@/app/_hooks/search-params';

export type SearchBlockProps = {
  numberOfEventsPerPage: number;
  label?: string;
  placeholder?: string;
};

export const SearchBlock = (accountSlug: string): ComponentConfig<SearchBlockProps> => {
  const t = useI18n();
  const keyword = useKeywordFilter();
  const [countText, setCountText] = useState<number>(0);
  return {
    fields: {
      numberOfEventsPerPage: {
        type: 'number',
        label: 'Number of events to show',
      },
      label: { type: 'text', label: 'Search label' },
      placeholder: { type: 'text', label: 'Search placeholder' },
    },
    defaultProps: {
      numberOfEventsPerPage: 16,
      label: 'Search',
      placeholder: 'Type here...',
    },
    render: ({ numberOfEventsPerPage = 16, label, placeholder }) => {
      const years = trpc.library.getAllAccountEventYears.useQuery(
        { accountSlug },
        {
          staleTime: 1000 * 60 * 60 * 24,
        },
      );
      return (
        <div className="flex items-center justify-center px-5 pb-20 pt-7 lg:px-12 lg:pt-20">
          <div className="flex max-w-[100rem] flex-1 flex-col">
            <div className="mb-8 flex flex-col justify-between gap-4 lg:flex-row lg:items-center lg:gap-0 lg:border-b lg:pb-8">
              <h3 className="font-condensed text-2xl font-semibold sm:text-3xl lg:text-4xl">
                {t('search.results', { count: countText })}: <span className="font-normal">{keyword}</span>
              </h3>
              <div className="flex flex-col gap-2 sm:flex-row sm:items-center lg:ml-0">
                <SearchInput
                  accountSlug={accountSlug}
                  label={label}
                  placeholder={placeholder}
                  className="flex-1 border md:flex-initial"
                  initialKeyword={keyword}
                />
                {years.data && (
                  <div className="-ml-3 sm:ml-0">
                    <YearSelect years={years.data} />
                  </div>
                )}
              </div>
            </div>
            <SearchEvents
              accountSlug={accountSlug}
              numberOfEventsPerPage={numberOfEventsPerPage}
              keyword={keyword}
              countText={countText}
              setCountText={setCountText}
            />
          </div>
        </div>
      );
    },
  };
};
