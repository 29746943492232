import { useSearchParams } from 'next/navigation';

// Hook for getting the page number parameter
export const usePageFilter = () => {
  const searchParams = useSearchParams();
  const pageParam = searchParams.get('page') || '1';
  const pageNum = parseInt(pageParam);
  return pageNum;
};

// Hook for getting the order filter parameter
export const useOrderFilter = () => {
  const searchParams = useSearchParams();
  const orderFilterParam = searchParams.get('order') || 'desc';
  const orderFilter: 'asc' | 'desc' = orderFilterParam === 'asc' ? 'asc' : 'desc';
  return orderFilter;
};

// Hook for getting the year filter parameter
export const useYearFilter = () => {
  const searchParams = useSearchParams();
  return searchParams.get('year') || 'all';
};

export const useKeywordFilter = () => {
  const searchParams = useSearchParams();
  return searchParams.get('keyword') || '';
};