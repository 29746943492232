'use client';

import { useSearchParams, useRouter } from 'next/navigation';

import { useOrderFilter } from '@/app/_hooks/search-params';
import { useI18n } from '@/app/(library-site)/locales/client';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@inderes/videosync-ui';

export const OrderSelect = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const orderFilter = useOrderFilter();
  const t = useI18n();

  const handleOrderChange = (value: 'desc' | 'asc') => {
    const params = new URLSearchParams(searchParams.toString());
    params.set('order', value);

    // Reset page (if exists)
    if (params.has('page')) {
      params.set('page', '1');
    }
    router.push('?' + params.toString(), { scroll: false });
  };

  return (
    <Select onValueChange={handleOrderChange} value={orderFilter}>
      <SelectTrigger
        className="text-primary-alt w-auto max-w-[150px] justify-start gap-2 border-none bg-transparent text-base hover:underline"
        chevronClassName="opacity-100"
      >
        <SelectValue placeholder={t('order.by.aria')} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="desc">{t('order.by.latest')}</SelectItem>
          <SelectItem value="asc">{t('order.by.oldest')}</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
