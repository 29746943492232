import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@inderes/videosync-ui';
import { useSearchParams, useRouter } from 'next/navigation';

import { useYearFilter } from '@/app/_hooks/search-params';
import { useI18n } from '@/app/(library-site)/locales/client';

export const YearSelect = ({ years }: { years: string[] }) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const yearFilter = useYearFilter();
  const t = useI18n();

  // Push the year filter to the URL
  const handleYearFilterChange = (value: string) => {
    const params = new URLSearchParams(searchParams.toString());
    if (years.includes(value)) {
      params.set('year', value);
    } else {
      params.set('year', 'all');
    }

    // Reset page (if exists)
    if (params.has('page')) {
      params.set('page', '1');
    }
    router.push('?' + params.toString(), { scroll: false });
  };

  function getSelectValue() {
    if (yearFilter === 'all') {
      return 'all';
    } else if (years.includes(yearFilter)) {
      return yearFilter;
    } else {
      return 'invalid';
    }
  }

  function isYearInData() {
    return years.includes(yearFilter);
  }

  return (
    <Select onValueChange={handleYearFilterChange} value={getSelectValue()}>
      <SelectTrigger
        className="text-primary-alt w-auto max-w-[150px] justify-start gap-2 whitespace-nowrap border-none bg-transparent text-base hover:underline"
        chevronClassName="opacity-100"
      >
        <SelectValue placeholder={t('filter.by.year.placeholder')} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="all">{t('filter.by.year.all')}</SelectItem>
          {years.map((year) => (
            <SelectItem key={year} value={year}>
              {t('filter.by.year.year', { year })}
            </SelectItem>
          ))}
          {yearFilter && yearFilter !== 'all' && !isYearInData() && (
            <SelectItem value="invalid" key="invalid">
              {t('filter.by.year.invalid')}
            </SelectItem>
          )}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
