import { z } from 'zod';
import { TurboBridgeConferenceInfoCall } from '.';
import { ChatboardMessage, Prisma, RegisterData } from '@inderes/videosync-database';

export const REQUESTED_TO_SPEAK_USER_DATA_KEY = 'sr';

export const WebStudioRole = {
  admin: 'admin',
  speaker: 'speaker',
  viewer: 'viewer',
  moderation: 'moderation',
} as const;
export type WebStudioRole = keyof typeof WebStudioRole;

export interface WebStudioUser {
  id: string;
  role: WebStudioRole;
}

export const WebStudioState = {
  LOBBY: 'LOBBY',
  GETTING_READY: 'GETTING_READY',
  SETUP_LIVE: 'SETUP_LIVE',
  SETUP_LIVE_LOADING: 'SETUP_LIVE_LOADING',
  SETUP_LIVE_ERROR: 'SETUP_LIVE_ERROR',
  GO_LIVE: 'GO_LIVE',
  LIVE_NOT_RECORDING: 'LIVE_NOT_RECORDING',
  GO_LIVE_LOADING: 'GO_LIVE_LOADING',
  GO_LIVE_ERROR: 'GO_LIVE_ERROR',
  LIVE: 'LIVE',
  LIVE_PAUSING: 'LIVE_PAUSING',
  LIVE_PAUSED: 'LIVE_PAUSED',
  LIVE_RESUMING: 'LIVE_RESUMING',
  LIVE_ENDING: 'LIVE_ENDING',
  LIVE_ENDED: 'LIVE_ENDED',
  INTERRUPTED: 'INTERRUPTED',
  PROCESSING_VIDEO: 'PROCESSING_VIDEO',
} as const;

export type WebStudioState = keyof typeof WebStudioState;

export const WebStudioTokenSchema = z.object({
  code: z.string(),
  eventId: z.string(),
  // TODO: After Moderation view has its own route .nullable() should be removed
  dailyRoomId: z.string().nullable(),

  user: z.object({
    id: z.string(),
    // from WebStudioRole
    role: z.nativeEnum(WebStudioRole),
    username: z.string().optional(),
  }),
  exp: z.number(),
  iat: z.number(),
  jti: z.string(),
});
/* TAB */
export const RightPanelTabType = {
  activityfeed: 'activity-feed',
  presentationslides: 'presentation-slides',
  people: 'people',
  chat: 'chat',
  polls: 'polls',
} as const;

export type RightPanelTabType = keyof typeof RightPanelTabType;
export interface TabSubItem {
  label: string;
  key: AllTabTypes;
}

export interface ActivityFeedTab {
  key: typeof RightPanelTabType.activityfeed;
  unseenCount: number;
  label: string;
  items: TabSubItem[];
}

interface PresentationSlideTab {
  key: typeof RightPanelTabType.presentationslides;
  label: string;
  items: TabSubItem[];
}

export interface PeopleTab {
  key: typeof RightPanelTabType.people;
  label: string;
  unseenCount: number;
  items: TabSubItem[];
}

export interface ChatTab {
  key: typeof RightPanelTabType.chat;
  label: string;
  unseenCount: number;
  items: any[];
}
export interface PollsTab {
  key: typeof RightPanelTabType.polls;
  label: string;
  items: any[];
}

export type TabType = PeopleTab | ActivityFeedTab | PresentationSlideTab | ChatTab | PollsTab;

/* TAB SUB ITEMS */
export const WebStudioActivityFeedTabVariables = {
  all: 'all',
  messages: 'messages',
  polls: 'polls',
} as const;

export const WebStudioPresentationTabVariables = {
  slides: 'slides',
  presentation: 'presentation',
} as const;

export const WebStudioPeopleTabVariables = {
  all: 'all',
  online: 'online',
  registered: 'registered',
  inQue: 'inQue',
} as const;
export type WebStudioActivityFeedTab = keyof typeof WebStudioActivityFeedTabVariables;
export type WebStudioPresentationTab = keyof typeof WebStudioPresentationTabVariables;
export type WebStudioPeopleTab = keyof typeof WebStudioPeopleTabVariables;
export type AllTabTypes = WebStudioActivityFeedTab | WebStudioPresentationTab | WebStudioPeopleTab;

export const AllPeopleGroupedTypes = {
  handraised: 'handraised',
  online: 'online',
} as const;
export type AllPeopleGroupedTypes = keyof typeof AllPeopleGroupedTypes;

export interface RegisteredItemData {
  token: string;
  online: boolean;
  data: RegisterData[];
}

export interface RegisteredItemAnonymousData {
  token: string;
  online: boolean;
  count: number;
  name: string;
}
export const WebStudioPeopleItemVariables = {
  turboBridge: 'turboBridge',
  daily: 'daily',
  registered: 'registered',
  anonymous: 'anonymous',
} as const;

export type WebStudioPeopleItem = keyof typeof WebStudioPeopleItemVariables;
export interface TurboBridgePeopleItem {
  type: typeof WebStudioPeopleItemVariables.turboBridge;
  data: TurboBridgeConferenceInfoCall;
}
export interface RegisteredPeopleItem {
  type: typeof WebStudioPeopleItemVariables.registered;
  data: RegisteredItemData;
}
export interface RegisteredAnonymousPeopleItem {
  type: typeof WebStudioPeopleItemVariables.anonymous;
  data: RegisteredItemAnonymousData;
}
export interface TalkBackPeopleItem {
  type: typeof WebStudioPeopleItemVariables.daily;
  data: {
    session_id: string;
    [REQUESTED_TO_SPEAK_USER_DATA_KEY]?: string;
  };
}

export type AllOnlineUsers =
  | TurboBridgePeopleItem
  | RegisteredPeopleItem
  | TalkBackPeopleItem
  | RegisteredAnonymousPeopleItem;

interface TurboBridgePeopleItemWithGroup extends TurboBridgePeopleItem {
  listType: typeof AllPeopleGroupedTypes.online;
}
interface TurboBridgePeopleItemWithGroupHandRaised extends TurboBridgePeopleItem {
  listType: typeof AllPeopleGroupedTypes.handraised;
}
interface RegisteredPeopleItemGroup extends RegisteredPeopleItem {
  listType: typeof AllPeopleGroupedTypes.online;
}
export interface RegisteredAnonymousPeopleItemGroup extends RegisteredAnonymousPeopleItem {
  listType: typeof AllPeopleGroupedTypes.online;
}

interface TalkBackPeopleItemGroup extends TalkBackPeopleItem {
  listType: typeof AllPeopleGroupedTypes.handraised;
}
export type AllOnlineUsersWithGroups =
  | TurboBridgePeopleItemWithGroup
  | TurboBridgePeopleItemWithGroupHandRaised
  | RegisteredPeopleItemGroup
  | TalkBackPeopleItemGroup
  | RegisteredAnonymousPeopleItemGroup;

export const PollType = {
  active: 'Going on!',
  results: 'Showing results',
  pending: 'Waiting',
} as const;

export enum PollTypes {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  TEXT = 'text',
  RATING = 'rating',
}

export const pollOptions = [
  {
    type: PollTypes.CHECKBOX,
    title: 'Multiple choices',
    description: 'Choose one or multiple answers',
  },
  {
    type: PollTypes.RADIO,
    title: 'Radio',
    description: 'Choose one of the answers',
  },
  {
    type: PollTypes.TEXT,
    title: 'Open text',
    description: 'Type in a free form response',
  },
  {
    type: PollTypes.RATING,
    title: 'Rating',
    description: 'Submit a rating between one and five',
  },
];

export type DynamicMessage = {
  text: string;
  order: Date;
  id: string;
};

export const BakcstageChatMessageType = {
  chat: 'chat',
  dynamic: 'dynamic',
} as const;

export type BackstageChatFeedItemType = keyof typeof BakcstageChatMessageType;

export interface BackstageChatMessageDynamicMessage {
  type: typeof BakcstageChatMessageType.dynamic;
  data: DynamicMessage;
}

interface BakcstageChatMessageChatMessage {
  type: typeof BakcstageChatMessageType.chat;
  data: ChatboardMessage;
}

export type BackstageChatMessageTypes = BackstageChatMessageDynamicMessage | BakcstageChatMessageChatMessage;

export type Poll = Prisma.PollGetPayload<{
  select: {
    id: true;
    answers: true;
    enabled: true;
    event: true;
    numberOfStars: true;
    showResults: true;
    title: true;
    votes: true;
    type: true;
  };
}>;

export const FeedItemType = {
  chat: 'chat',
  dynamic: 'dynamic',
  poll: 'poll',
} as const;

export type ActivityFeedItemType = keyof typeof FeedItemType;
interface FeedItemDynamicMessage {
  type: typeof FeedItemType.dynamic;
  data: DynamicMessage;
}

interface FeedItemChatMessage {
  type: typeof FeedItemType.chat;
  data: ChatboardMessage;
}
export interface FeedItemPoll {
  type: typeof FeedItemType.poll;
  data: Poll;
}

export type FeedItem = FeedItemPoll | FeedItemDynamicMessage | FeedItemChatMessage;
