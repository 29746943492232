'use client'

import { cn } from '@inderes/videosync-ui';
import { ComponentConfig } from '@measured/puck';
import { ExternalLinkIcon } from 'lucide-react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';

import { MobileNavigationMenu } from './mobile-navigation-menu';
import { useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { focusNextElement, linkHelper } from '@/app/(library-site)/utils';

export type NavigationMenuProps = {
  links: {
    href: string;
    label: string;
    labels?: {
      code: string;
      name: string;
    }[];
  }[];
  externalLinks: {
    href: string;
    label: string;
    labels?: {
      code: string;
      name: string;
    }[];
  }[];
};

export const NavigationMenu = (accountSlug: string): ComponentConfig<NavigationMenuProps> => {
  const pathname = usePathname();
  const locale = useCurrentLocale();
  const t = useI18n();
  // TODO: fetch languages & add dynamically to fields
  return {
    fields: {
      links: {
        type: 'array',
        label: 'Links within the site',
        arrayFields: {
          href: { type: 'text' },
          label: { type: 'text', label: 'Label' },
          labels: {
            type: 'array',
            label: 'Labels (multilanguage)',
            arrayFields: {
              code: {
                type: 'select',
                label: 'Language',
                options: [
                  // TODO: get from database
                  { label: 'Finnish', value: 'fi' },
                  { label: 'Swedish', value: 'sv' },
                ],
              },
              name: { type: 'text' },
            },
          },
        },
      },
      externalLinks: {
        type: 'array',
        label: 'External links',
        arrayFields: {
          href: { type: 'text' },
          label: { type: 'text' },
          labels: {
            type: 'array',
            label: 'Labels',
            arrayFields: {
              code: {
                type: 'select',
                label: 'Language',
                options: [
                  // TODO: get from database
                  { label: 'Finnish', value: 'fi' },
                  { label: 'Swedish', value: 'sv' },
                ],
              },
              name: { type: 'text' },
            },
          },
        },
      },
    },
    defaultProps: {
      links: [],
      externalLinks: [],
    },
    render: ({ links, externalLinks }: NavigationMenuProps) => (
      <>
        <MobileNavigationMenu accountSlug={accountSlug} links={links} externalLinks={externalLinks} />
        <div className="bg-accent relative hidden items-center justify-center px-12 lg:flex">
          <button
            className="absolute -top-4 left-0 h-0 overflow-hidden opacity-0 focus-visible:overflow-visible focus-visible:opacity-100"
            onClick={focusNextElement}
          >
            {t('skip.nav')}
          </button>
          <div className="flex max-w-[100rem] flex-1 items-center justify-between border-b border-b-white pb-2">
            <nav className="flex items-center gap-1 xl:gap-2.5">
              {links.map((link, index) => {
                if (link.href) {
                  const { linkHref, isActive } = linkHelper({ pathname, href: link.href, locale, accountSlug });
                  // Get the link label in the current locale
                  const linkLabel = link.labels?.find((label) => label.code === locale)?.name || link.label;
                  return (
                    <Link
                      prefetch={false}
                      href={linkHref}
                      key={link.href + index.toString()}
                      className={cn(
                        'text-primary-alt hover:bg-primary-foreground flex h-8 items-center justify-center whitespace-nowrap rounded px-1.5 text-sm transition xl:px-2 desktop:text-base',
                        isActive && 'bg-primary-foreground',
                      )}
                    >
                      {linkLabel}
                    </Link>
                  );
                }
                return <a key={link.label}>{link.label}</a>;
              })}
            </nav>
            <div className="flex items-center gap-2.5">
              {externalLinks.map((link, index) => {
                const linkLabel = link.labels?.find((label) => label.code === locale)?.name || link.label;
                return (
                  <a
                    href={link.href}
                    target="_blank"
                    key={link.href + '1'}
                    className="text-primary-alt hover:bg-primary-foreground flex h-8 items-center justify-center gap-1 whitespace-nowrap rounded px-1.5 text-sm transition xl:px-2 desktop:text-base"
                    aria-label={linkLabel + ' ' + t('external.link.aria')}
                  >
                    {linkLabel}
                    {/* TODO: replace icon */}
                    <ExternalLinkIcon className="h-4 w-4" aria-hidden />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </>
    ),
  };
};
