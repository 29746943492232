import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import superjson from "superjson";
import Cookies from "js-cookie";
import { getBaseUrl } from '../common/env.config';

import { type AppRouter } from "@/server";

/**
 * This is used in places where we cannot use the serverClient or react-query 
 * based client (it is not called from react component).
 * 
 * eg. In a third party library that has some function to fetch remote data but is
 * not inside a react component. Good example of this is the Puck library and it's
 * external field type that has a "dumb" fetchList function that is not a react
 * component.
 */

const vanillaClient = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [
    httpBatchLink({
      url: `${getBaseUrl()}/api/trpc`,
      headers() {

        const jwt = Cookies.get('jwt');
        const authorization = jwt ? `Bearer ${jwt}` : undefined;

        return {
          authorization,
        };
      }
    }),
  ],
});

export default vanillaClient;