'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useState } from 'react';
import { ExternalLinkIcon, X } from 'lucide-react';
import { cn, Button, MenuAltIcon, Dialog, DialogTrigger, DialogContent, DialogClose } from '@inderes/videosync-ui';

import { useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { linkHelper } from '@/app/(library-site)/utils';
import { SearchInput } from '../search/search-input';

export type MobileNavigationMenuProps = {
  accountSlug: string;
  links: {
    href: string;
    label: string;
    labels?: {
      code: string;
      name: string;
    }[];
  }[];
  externalLinks: {
    href: string;
    label: string;
    labels?: {
      code: string;
      name: string;
    }[];
  }[];
};

export const MobileNavigationMenu = ({ links, externalLinks, accountSlug }: MobileNavigationMenuProps) => {
  const pathname = usePathname();
  const locale = useCurrentLocale();
  const t = useI18n();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative block lg:hidden">
      <Dialog open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
        <DialogTrigger asChild>
          <Button
            className="text-primary-alt absolute -top-[4.25rem] right-2 !bg-transparent"
            aria-label={t('menu.aria')}
          >
            <MenuAltIcon aria-hidden />
          </Button>
        </DialogTrigger>
        <DialogContent
          hideCloseButton
          className="bg-accent flex h-full max-h-screen max-w-full flex-col overflow-y-auto !rounded-none border-none px-8 pt-24 shadow-none"
        >
          <DialogClose asChild>
            <Button
              className="text-primary-alt absolute right-8 top-8 !bg-transparent"
              aria-label={t('menu.close.aria')}
            >
              {isOpen ? <X aria-hidden /> : <MenuAltIcon aria-hidden />}
            </Button>
          </DialogClose>
          <div className="flex flex-col items-start">
            <nav className="mb-6 flex flex-col items-start gap-6">
              {links.map((link, index) => {
                if (link.href) {
                  const { linkHref, isActive } = linkHelper({ pathname, href: link.href, locale, accountSlug });
                  // Get the link label in the current locale
                  const linkLabel = link.labels?.find((label) => label.code === locale)?.name || link.label;
                  return (
                    <Link
                      prefetch={false}
                      href={linkHref}
                      key={link.href + index.toString()}
                      className={cn(
                        'text-primary font-condensed text-3xl font-semibold',
                        isActive && 'text-foreground',
                      )}
                    >
                      {linkLabel}
                    </Link>
                  );
                }
                return <a key={link.label}>{link.label}</a>;
              })}
            </nav>
            <div className="flex flex-col items-start gap-6">
              {externalLinks.map((link, index) => {
                const linkLabel = link.labels?.find((label) => label.code === locale)?.name || link.label;
                return (
                  <a
                    href={link.href}
                    target="_blank"
                    key={link.href + '1'}
                    className="text-primary font-condensed flex items-center justify-center gap-4 text-3xl font-semibold"
                    aria-label={linkLabel + ' ' + t('external.link.aria')}
                  >
                    {linkLabel}
                    {/* TODO: replace icon */}
                    <ExternalLinkIcon className="h-5 w-5" aria-hidden />
                  </a>
                );
              })}
            </div>
          </div>
          <SearchInput accountSlug={accountSlug} className="mb-4 mt-auto" endpoint="/search" />
        </DialogContent>
      </Dialog>
    </div>
  );
};
