'use client';

import { format, isSameDay } from 'date-fns';
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  useToast,
  Button,
  PaperplaneAltIcon,
  Label,
  Input,
  Toaster,
  Checkbox,
  CircleIcon,
} from '@inderes/videosync-ui';
import { useCallback, useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { CopyIcon } from 'lucide-react';
import { MarkdownRenderer } from '@/app/common/components/markdown-renderer/markdown-renderer';
import { EventState } from '@inderes/videosync-types';

import { GetEventData } from '@/app/(event-site)/data';
import { playerCurrentTimeAtom } from '@/app/(library-site)/library-state';
import { useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { focusNextElement, linkHelper } from '@/app/(library-site)/utils';
import { store } from '@/app/(library-site)/jotai-service';

const LibraryPlayer = dynamic(
  () => import('@/app/(library-site)/components/library-bitmovin').then((mod) => mod.LibraryPlayer),
  {
    ssr: false,
    loading: () => (
      <Image
        className="w-full"
        width={1568}
        height={882}
        src="/images/player-placeholder.svg"
        alt="Player placeholder"
      />
    ),
  },
);

const StateTextContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="flex min-h-[20rem] items-center justify-center text-2xl">{children}</div>
);

export const EventContent = ({ accountSlug, eventData }: { accountSlug: string; eventData: GetEventData }) => {
  const t = useI18n();
  const locale = useCurrentLocale();
  const pathname = usePathname();
  const [urlToCopy, setUrlToCopy] = useState('');
  const [startTime, setStartTime] = useState(0);

  const { toast } = useToast();

  const StateHandler = useCallback(() => {
    if (!eventData) return;
    if ([EventState.LIVE, EventState.PUBLISH_RECORDING].includes(eventData.state)) {
      return (
        <div className="relative">
          <button
            className="absolute -top-8 left-0 h-0 overflow-hidden opacity-0 focus-visible:overflow-visible focus-visible:opacity-100"
            onClick={focusNextElement}
          >
            {t('skip.player')}
          </button>
          <LibraryPlayer eventData={eventData} accountSlug={accountSlug} />
        </div>
      );
    }
    switch (eventData.state) {
      case EventState.SHARE:
        return <StateTextContainer>{eventData.hidden.live}</StateTextContainer>;
      case EventState.EDIT_RECORDING:
        return <StateTextContainer>{eventData.hidden.od}</StateTextContainer>;
      case EventState.LIVE_PAUSED:
        return <StateTextContainer>{eventData.hidden.pause}</StateTextContainer>;
      default:
        return '';
    }
  }, [eventData, t, accountSlug]);

  const handleCopyUrl = () => {
    if (typeof window !== 'undefined') {
      void navigator.clipboard.writeText(urlToCopy);
      toast({
        title: 'Linkki kopioitu leikepöydälle',
      });
    }
  };

  const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const time = !Number.isNaN(e.target.valueAsNumber) ? e.target.valueAsNumber : null;
    if (!time || time === 0) {
      setUrlToCopy(window.location.href);
      setStartTime(0);
    }
    if (time && time > 0) {
      setStartTime(time);
      setUrlToCopy(`${window.location.href}?start=${time}`);
    }
  };

  const handleCurrentTimeToggle = (checked: boolean) => {
    if (checked) {
      /* Note: We get the playerCurrentTimeAtom directly from Jotai store to 
      prevent unnecessary re-renders by having the atom as a dependency */
      const playerCurrentTime = store.get(playerCurrentTimeAtom);
      const currentTime = Math.round(playerCurrentTime);
      setUrlToCopy(`${window.location.href}?start=${currentTime}`);
      setStartTime(currentTime);
    } else {
      setUrlToCopy(window.location.href);
      setStartTime(0);
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const newUrl = typeof window !== 'undefined' ? window.location.href : '';
    if (urlToCopy === '' && newUrl !== urlToCopy) {
      setUrlToCopy(newUrl);
    }
  }, [urlToCopy]);

  if (!eventData) {
    return (
      <div className="p-12 text-center">
        Please add existing event slug to URL to see EventContent component: &quot;/v/event-slug&quot;
      </div>
    );
  }

  const isUpcoming = new Date(eventData.publishingDate) > new Date() && eventData.state === EventState.SHARE;
  const isToday = isSameDay(new Date(eventData.publishingDate), new Date());

  const title = (locale === 'sv' && eventData?.translation?.sv?.title) || eventData.title || '';
  const description =
    (locale === 'sv' && eventData?.translation?.sv?.about?.description) || eventData.about?.description || '';

  return (
    <div className="bg-accent flex flex-col items-center justify-between gap-16 p-5 pt-0 lg:p-12 lg:pt-8">
      <div className="flex w-full max-w-[50rem] flex-1 flex-col gap-6 lg:gap-8 [@media(width>=1600px)]:max-w-[65rem]">
        <StateHandler />
        <div className="flex flex-1 flex-col justify-between lg:flex-row">
          <div className="lg:max-w-[28.125rem] [@media(width>=1600px)]:max-w-[40rem]">
            <p className="text-muted text-sm">
              {eventData.state === EventState.LIVE ? (
                <span className="text-primary flex items-center gap-2 text-lg font-semibold">
                  <CircleIcon aria-hidden />
                  <span>Live</span>
                </span>
              ) : isUpcoming && isToday ? (
                <span className="text-destructive font-semibold">
                  {t('upcoming.today', { time: format(eventData.publishingDate, 'HH:mm') })}
                </span>
              ) : (
                <span>
                  {isUpcoming && <span className="text-muted">{t('upcoming')} </span>}
                  {format(eventData.publishingDate, 'dd.MM.yyyy - HH:mm')}
                </span>
              )}
            </p>
            {title && <h2 className="font-condensed mt-3 text-3xl font-semibold">{title}</h2>}
            {description && (
              <MarkdownRenderer input={description} className="prose text-muted-foreground mt-5 lg:mt-6" />
            )}
          </div>
          <div className="mt-6 flex w-full flex-col items-start gap-1 lg:mt-0 lg:max-w-[13.75rem] lg:gap-2.5">
            <Dialog>
              <DialogTrigger asChild>
                <Button className="text-primary-alt flex items-center gap-3 px-0 text-base" variant="link">
                  {t('share')} <PaperplaneAltIcon aria-hidden />
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[750px]">
                <DialogHeader>
                  <DialogTitle className="text-2xl">{t('share.title')}</DialogTitle>
                </DialogHeader>
                <div className="relative">
                  <Label htmlFor="page-url" className="absolute left-3 top-3 z-10 font-semibold">
                    {t('share.url')}
                  </Label>
                  <Input id="page-url" className="h-14 pb-4 pr-14 pt-8" value={urlToCopy} readOnly />
                  <Button
                    className="bg-primary-alt text-primary-foreground absolute inset-1 left-auto h-auto w-12 p-0"
                    onClick={handleCopyUrl}
                  >
                    <CopyIcon size={24} aria-hidden />
                  </Button>
                </div>
                <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
                  <div className="relative">
                    <Label htmlFor="start-time" className="absolute left-3 top-3 z-10 font-semibold">
                      {t('share.starttime')}
                    </Label>
                    <Input
                      id="start-time"
                      className="h-14 pb-4 pr-14 pt-8"
                      value={startTime}
                      onChange={handleStartTimeChange}
                      type="number"
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <Checkbox id="share-from-current-time" onCheckedChange={handleCurrentTimeToggle} />
                    <Label className="mt-1" htmlFor="share-from-current-time">
                      {t('share.current.time')}
                    </Label>
                  </div>
                </div>
              </DialogContent>
              <Toaster />
            </Dialog>
            <div className="border-primary/15 mb-3 w-full border-b" />
            <div className="flex items-start gap-2.5 lg:flex-col">
              {eventData?.tags?.map((tag) => {
                const tagLabel = tag?.[locale];
                const { linkHref } = linkHelper({ pathname, accountSlug, href: `/search?keyword=${tagLabel}`, locale });
                return (
                  <Link
                    href={linkHref}
                    className="text-primary-alt bg-primary-foreground hover:bg-primary-alt hover:text-primary-foreground rounded px-3 py-2 text-xs font-semibold transition"
                    key={tag.id}
                    prefetch={false}
                  >
                    {tag?.[locale]}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
