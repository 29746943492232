import React from 'react';
import Markdown from 'markdown-to-jsx';
import { parseEventTimes } from '../../../(event-site)/utils';


interface Props {
  input: string;
  className?: string;
  publishingDate?: Date;
}

// Also handles eventTime syntax
export const MarkdownRenderer = ({ input, publishingDate, className }: Props) => {
  return <Markdown className={className}>{parseEventTimes(input || '', publishingDate)}</Markdown>;
};
