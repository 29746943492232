'use client'

import { ComponentConfig } from '@measured/puck';
import Link from 'next/link';
import { ArrowRightIcon } from '@inderes/videosync-ui';
import { usePathname } from 'next/navigation';

import { MarkdownRenderer } from '@/app/common/components/markdown-renderer/markdown-renderer';
import { useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { linkHelper } from '@/app/(library-site)/utils';

export type TextContentBlockProps = {
  text: string;
  texts?: {
    code: string;
    text: string;
  }[];
};

export const TextContentBlock = (accountSlug: string): ComponentConfig<TextContentBlockProps> => {
  const t = useI18n();
  const locale = useCurrentLocale();
  const pathname = usePathname();
  return {
    fields: {
      text: { type: 'textarea', label: 'Text (supports HTML & MD)' },
      texts: {
        type: 'array',
        label: 'Texts (multilanguage)',
        arrayFields: {
          code: {
            type: 'select',
            label: 'Language',
            options: [
              // TODO: get from database
              { label: 'Finnish', value: 'fi' },
              { label: 'Swedish', value: 'sv' },
            ],
          },
          text: { type: 'textarea', label: 'Text' },
        },
      },
    },
    defaultProps: {
      text: '',
    },
    render: ({ text, texts }) => {
      const correctText = (texts?.find((t) => t.code === locale) || texts?.[0])?.text || text;
      const { linkHref } = linkHelper({ pathname, accountSlug, href: '/', locale });
      return (
        <div className="flex h-full w-full justify-center">
          <div className="mx-5 mb-16 mt-20 flex w-full max-w-[46rem] flex-1 flex-col justify-between">
            {correctText ? (
              <MarkdownRenderer input={correctText} className="prose max-w-full" />
            ) : (
              <div>
                <h1 className="mb-6 text-3xl font-semibold">Text will be displayed here</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                  officia deserunt mollit anim id est laborum.
                </p>
              </div>
            )}
            <Link
              prefetch={false}
              href={linkHref}
              className="text-primary-alt mt-14 flex items-center gap-3 font-semibold hover:underline"
            >
              <ArrowRightIcon className="rotate-180" aria-hidden /> {t('go.to.home')}
            </Link>
          </div>
        </div>
      );
    },
  };
};
