// All sorts of helpers functions used within the app router
import { notFound, redirect } from 'next/navigation';
import { EventState } from '@inderes/videosync-types';
import { formatInTimeZone } from 'date-fns-tz';
import { enGB } from 'date-fns/locale';
import { TRPCError } from '@trpc/server';

// react query config
export const queryConfig = {
  staleTime: 1000 * 3, // 3 seconds
  refetchOnWindowFocus: false,
};

// Parse {eventtime} notations in text to actual dates
export const parseEventTimes = (textToParse: string, dateToDisplay?: Date) => {
  if (!textToParse) return '';
  if (!dateToDisplay || !(dateToDisplay instanceof Date)) return textToParse;
  /* Find all date notations, e.g. {eventtime;DD.MM.YYYY}, in text */
  const foundDateNotations = textToParse.match(/({\s*eventtime\s*(}|\s*;.+?}))+/g);
  /* If no dates found, return original text */
  if (!foundDateNotations || foundDateNotations?.length === 0) {
    return textToParse;
  }
  let newText = textToParse;
  /* Loop through all the notations found */
  foundDateNotations?.forEach((dateNotation) => {
    /* Remove brackets, spaces, and 'eventtime;' from the 
      notation to get the optional date formattings */
    const dateFormattings = dateNotation.replace(/({|}|eventtime\s*;*)+/g, '');
    const dateWithNoBracket = dateFormattings.replace(/\[|\]/g, "'");

    /* Get the date formatter from the syntax */
    const dateFormatter = dateWithNoBracket.split(';')[0];

    /* FYI: separate UTC offset formatter was removed due to new timezone support in normal formatter! */

    /* Get user timezone */
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    /* Format date using date formatter. Defaults to 'dd.MM.yyyy' if formatter wasn't set */
    // TODO: if more event-site language versions are added, handle locales here
    const dateAsText = formatInTimeZone(dateToDisplay, tz, dateFormatter || 'dd.MM.yyyy', { locale: enGB });
    /* Replace the date notation in text with the actual date */
    newText = newText.replace(dateNotation, dateAsText);
  });
  return newText;
};

export const stateSelectorStates = [
  { value: EventState.SHARE, label: 'Pre-live' },
  { value: EventState.LIVE, label: 'Live' },
  { value: EventState.LIVE_PAUSED, label: 'Paused' },
  { value: EventState.EDIT_RECORDING, label: 'Live ended' },
  { value: EventState.PUBLISH_RECORDING, label: 'Published recording' },
];

export const getStateClassName = (eventState: number) => {
  switch (eventState) {
    case EventState.REGISTRATION:
      return 'es-event-state-registration';
    case EventState.SHARE:
      return 'es-event-state-share';
    case EventState.LIVE:
      return 'es-event-state-live';
    case EventState.LIVE_PAUSED:
      return 'es-event-state-live-paused';
    case EventState.EDIT_RECORDING:
      return 'es-event-state-edit-recording';
    case EventState.PUBLISH_RECORDING:
      return 'es-event-state-publish-recording';
    default:
      return 'es-event-state-build';
  }
};

export const defaultHiddenTexts = {
  en: {
    live: 'The live broadcast starts on {eventtime;dd MMMM y [at] HH:mm zzz}',
    pause: 'The live broadcast is paused, we will resume shortly.',
    od: 'Thank you for participating! The recording will be available soon.',
  },
  fi: {
    live: 'Lähetys alkaa {eventtime;dd.MM.yyyy [klo] HH:mm zzz}',
    pause: 'Lähetys on tauolla, jatkamme pian.',
    od: 'Kiitos osallistumisesta! Tallenne on katsottavissa pian.',
  },
  // copilot generated this not sure is this right
  sv: {
    live: 'Direktsändningen börjar den {eventtime;dd.MM.yyyy [kl.] HH:mm zzz}',
    pause: 'Direktsändningen är pausad, vi fortsätter snart.',
    od: 'Tack för att du deltog! Inspelningen finns snart tillgänglig.',
  },
};

// Common error handler for all pages
export const handlePageErrors = ({
  error,
  accountSlug,
  eventSlug,
  videosyncDomain,
  loginRedirect,
}: {
  error: any;
  accountSlug: string;
  eventSlug: string;
  videosyncDomain: string;
  loginRedirect?: string;
}) => {
  const loginRedirectQuery = loginRedirect || 'ir-embed';
  // Log the full error details for debugging
  console.error('Error fetching event site data:', {
    error,
    accountSlug,
    eventSlug,
    timestamp: new Date().toISOString(),
  });

  if (error instanceof TRPCError) {
    switch (error.code) {
      case 'UNAUTHORIZED': {
        if (error.message === 'REDIRECT_TO_LOGIN') {
          console.error('User unauthorized, redirecting to login');
          const videosyncUrl = new URL(videosyncDomain);
          videosyncUrl.hostname = `${accountSlug}.${videosyncUrl.hostname}`;
          const loginUrl = `${videosyncUrl.href}${eventSlug}/password?redirect=${loginRedirectQuery}`;
          redirect(loginUrl);
        } else {
          console.error('User unauthorized, returning not found');
        }
        break;
      }
      case 'NOT_FOUND': {
        console.log('Event not found');
        return notFound();
      }
      case 'INTERNAL_SERVER_ERROR': {
        // Log the internal server error with full context
        console.error('Internal server error in getEventSiteData:', {
          message: error.message,
          cause: error.cause,
          accountSlug,
          eventSlug,
        });
        throw error; // Re-throw to be caught by Next.js error boundary
      }
      default: {
        // Log unhandled TRPC error types
        console.error('Unhandled TRPC error:', {
          code: error.code,
          message: error.message,
          accountSlug,
          eventSlug,
        });
        throw error; // Re-throw to be caught by Next.js error boundary
      }
    }
  } else {
    // Handle non-TRPC errors
    console.error('Unexpected error in getEventSiteData:', {
      error: error instanceof Error ? error.message : error,
      accountSlug,
      eventSlug,
    });
    throw error; // Re-throw to be caught by Next.js error boundary
  }
};

// Helper hook for handling event metadata
export const handlePageMetadataErrors = ({
  error,
  accountSlug,
  eventSlug,
}: {
  error: any;
  accountSlug: string;
  eventSlug: string;
}) => {
  if (error instanceof TRPCError) {
    if (error.code === 'NOT_FOUND') {
      return notFound();
    }
    // Log other TRPC errors but still return notFound for security
    console.error('Error fetching event metadata:', {
      code: error.code,
      message: error.message,
      accountSlug,
      eventSlug,
    });
  } else {
    // Log unexpected errors
    console.error('Unexpected error in generateMetadata:', error);
  }
};
