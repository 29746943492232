'use client'

import { ComponentConfig } from '@measured/puck';
import { Button, Popover, PopoverContent, PopoverTrigger, GlobeIcon } from '@inderes/videosync-ui';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

import { MobileHeadingMenu } from './mobile-heading-menu';
import { useChangeLocale, useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { focusNextElement, linkHelper } from '@/app/(library-site)/utils';
import { SearchInput } from '../search/search-input';

export type HeadingBlockProps = {
  logo: string;
  title: string;
  subheading: string;
  search: {
    enabled: boolean;
    label?: string;
    placeholder?: string;
  };
  languagePicker: {
    enabled: boolean;
  };
};

export const HeadingBlock = (accountSlug: string): ComponentConfig<HeadingBlockProps> => {
  const t = useI18n();
  const locale = useCurrentLocale();
  const changeLocale = useChangeLocale();
  const pathname = usePathname();
  const { linkHref } = linkHelper({ pathname, accountSlug, href: '/', locale });
  return {
    fields: {
      logo: { type: 'text', label: 'Logo (needs a file picker)' },
      title: { type: 'text', label: 'Title' },
      subheading: { type: 'text', label: 'Subheading' },
      search: {
        type: 'object',
        objectFields: {
          enabled: {
            type: 'radio',
            label: 'Search input',
            options: [
              { label: 'On', value: true },
              { label: 'Off', value: false },
            ],
          },
          label: { type: 'text', label: 'Search label (empty for default fi/sv)' },
          placeholder: { type: 'text', label: 'Search placeholder (empty for default fi/sv)' },
        },
      },
      languagePicker: {
        type: 'object',
        objectFields: {
          enabled: {
            type: 'radio',
            label: 'Language picker',
            options: [
              { label: 'On', value: true },
              { label: 'Off', value: false },
            ],
          },
        },
      },
    },
    defaultProps: {
      logo: '',
      title: 'Heading',
      subheading: '',
      search: { enabled: false },
      languagePicker: { enabled: false },
    },
    render: ({ logo, title, subheading, search, languagePicker }) => (
      <>
        {languagePicker?.enabled && <MobileHeadingMenu />}
        <header className="bg-accent relative flex items-center justify-center p-5 lg:p-6 lg:px-12">
          <button
            className="absolute left-0 top-0 h-0 overflow-hidden opacity-0 focus-visible:overflow-visible focus-visible:opacity-100"
            onClick={focusNextElement}
          >
            {t('skip.header')}
          </button>
          <div className="flex max-w-[100rem] flex-1 items-center gap-6">
            <Link href={linkHref} className="flex items-center gap-4" prefetch={false}>
              {logo && (
                <Image
                  src={logo}
                  width={244}
                  height={65}
                  alt={['vn', 'valtioneuvosto'].includes(accountSlug) ? t('vn.logo.alt') : 'logo'}
                  className="h-auto w-40 lg:w-56"
                />
              )}
              {logo && title && <div className="border-primary/50 hidden h-10 border-l [@media(width>=460px)]:block" />}
              <div className="mt-1 hidden flex-col gap-1 [@media(width>=460px)]:flex">
                <h1 className="text-primary text-[15px] leading-[15px]">{title}</h1>
                <p className="text-primary text-[15px] font-light leading-[15px]">{subheading}</p>
              </div>
            </Link>
            {(search?.enabled || languagePicker?.enabled) && (
              <div className="ml-auto hidden gap-1.5 lg:flex">
                {search?.enabled && (
                  <SearchInput
                    label={search.label}
                    placeholder={search.placeholder}
                    accountSlug={accountSlug}
                    endpoint="/search"
                  />
                )}
                {/* TODO: Get locales from database */}
                {languagePicker?.enabled && (
                      <div className="bg-primary-foreground rounded p-1.5">
                  <Popover>
                    <PopoverTrigger asChild>
                        <Button className="bg-primary-alt text-primary-foreground gap-2 px-3 uppercase">
                          <span>{locale}</span> <GlobeIcon aria-hidden />
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="bg-primary-foreground flex w-auto flex-col gap-2 rounded p-1.5">
                      <Button className="bg-primary-alt text-primary-foreground" onClick={() => changeLocale('fi')}>
                        Suomi
                      </Button>
                      <Button className="bg-primary-alt text-primary-foreground" onClick={() => changeLocale('sv')}>
                        Svenska
                      </Button>
                    </PopoverContent>
                  </Popover></div>
                )}
              </div>
            )}
          </div>
        </header>
      </>
    ),
  };
};
