import { z } from 'zod';

export const glossaryListEntrySchema = z.object({
  id: z.string(),
  userName: z.string(),
  name: z.string().nonempty().max(60),
  names: z.array(z.string().nonempty().max(20)).max(250),
  items: z
    .array(
      z.object({
        name: z.string().max(60),
        description: z.string().max(200),
      }),
    )
    .max(750),
  created: z.date(),
});

export const glossaryListResponseSchema = z.array(glossaryListEntrySchema);

export const createOrUpdateGlossaryPayloadSchema = glossaryListEntrySchema.pick({
  name: true,
  names: true,
  items: true,
});

export const createOrUpdateCollectionFormSchema = z
  .object({
    id: z.string(),
    name: z.string().nonempty().max(60),
    names: z.string().refine(
      (val) => {
        const names = val.split('\n');
        const invalidNames = names.filter((name) => name.length > 20);
        return invalidNames.length === 0;
      },
      (val) => {
        const names = val.split('\n');
        const invalidNames = names.filter((name) => name.length > 20);
        return { message: 'Names must be max 20 characters long. Invalid names: ' + invalidNames.join(', ') };
      },
    ),
    items: z
      .array(
        z.object({
          name: z
            .string()
            .nonempty({
              message: 'Term name is required',
            })
            .max(60, {
              message: 'Term name must be max 60 characters long',
            }),
          description: z
            .string()
            .nonempty({
              message: 'Definition is required',
            })
            .max(200, {
              message: 'Definition must be max 200 characters long',
            }),
        }),
      )
      .max(750),
  });

export type GlossaryListEntry = z.infer<typeof glossaryListEntrySchema>;
export type GlossaryListResponse = z.infer<typeof glossaryListResponseSchema>;
export type CreateOrUpdateGlossaryPayload = z.infer<typeof createOrUpdateGlossaryPayloadSchema>;
export type CreateOrUpdateCollectionForm = z.infer<typeof createOrUpdateCollectionFormSchema>;
