'use client';

import { useChangeLocale, useCurrentLocale, useI18n } from '@/app/(library-site)/locales/client';
import { Button, Dialog, DialogTrigger, DialogContent, DialogClose, ChevronDownIcon } from '@inderes/videosync-ui';
import { X } from 'lucide-react';

export const MobileHeadingMenu = () => {
  const t = useI18n();
  const locale = useCurrentLocale();
  const changeLocale = useChangeLocale();

  const localeLabel = locale === 'fi' ? 'Suomi' : locale === 'sv' ? 'Svenska' : 'English';
  return (
    <div className="bg-primary-alt flex h-7 justify-end px-3 lg:hidden">
      <Dialog>
        <DialogTrigger asChild>
          <button
            className="text-primary-foreground flex items-center gap-2 text-sm"
            aria-label={t('language.menu.aria')}
          >
            {localeLabel} <ChevronDownIcon aria-hidden />
          </button>
        </DialogTrigger>
        <DialogContent
          hideCloseButton
          className="bg-accent flex h-full max-h-screen max-w-full flex-col overflow-y-auto !rounded-none border-none px-8 pt-12 shadow-none"
        >
          <DialogClose asChild>
            <Button
              className="text-primary-alt absolute right-8 top-8 !bg-transparent"
              aria-label={t('language.menu.close.aria')}
            >
              <X aria-hidden />
            </Button>
          </DialogClose>
          <div className="font-condensed flex flex-col items-start gap-6 text-3xl font-semibold">
            <h4>{t('language.menu.title')}</h4>
            <Button
              variant="link"
              className="font-condensed -ml-4 text-3xl font-semibold"
              onClick={() => changeLocale('fi')}
            >
              Suomi
            </Button>
            <Button
              variant="link"
              className="font-condensed -ml-4 text-3xl font-semibold"
              onClick={() => changeLocale('sv')}
            >
              Svenska
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
