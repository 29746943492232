import { EventState } from '@inderes/videosync-types';
const estimatedAudioBitrate = 128000;
const bestGuessForVideoBitrate = 2500000;

export const getLibraryPageMetadata = ({ accountSlug, title }: { accountSlug: string; title: string }) => {
  const previewImg = '';

  // TODO: Add description etc
  return {
    metadataBase: new URL('http://localhost:3006'), // Apparently this is okay for production too? -> Let's test & see if it causes problems
    title,
    openGraph: {
      images: [previewImg],
    },
  };
};

// Wraps the player data to match the expected format
// TODO: fix the type
export const wrapPlayerData = (eventData: any) => {
  if (!eventData) return;
  const isAudioEvent = eventData.media === 'audio event';
  const eventStateIsLive = eventData.state === EventState.LIVE;

  if (eventData.streams) {
    eventData.streams.hls = eventData.streams.http
      ? `/events/amlst:${eventData.streams.http.replace(/\.[^/.]+$/, '')}/playlist.m3u8`
      : '';
    eventData.streams.audio = eventData.streams.http
      ? `/mp4:${eventData.streams.http.replace(/\.[^/.]+$/, '')}/playlist.m3u8?wowzaaudioonly`
      : '';
    eventData.streams.estimatedBitrate =
      (isAudioEvent ? estimatedAudioBitrate : eventData.streams.abr[0]?.videoBitrate) || bestGuessForVideoBitrate;
  }
  if (eventStateIsLive) {
    if (eventData?.liveChannel?.streamingServer && !eventData.liveChannel.stream.endpointHLS.includes('://')) {
      eventData.streams.live = {
        hls:
          ((eventData.liveChannel?.streamingServer?.playbackUrl as string | undefined) || '') +
          ((eventData.liveChannel?.stream?.endpointHLS as string | undefined) || ''),
        estimatedBitrate: null,
      };
    } else {
      eventData.streams.live = {
        hls: eventData.liveChannel?.stream?.endpointHLS || null,
        estimatedBitrate: null,
      };
    }
  }
  if (eventData.streams?.live) {
    eventData.streams.live.estimatedBitrate = isAudioEvent ? estimatedAudioBitrate : bestGuessForVideoBitrate;
  }
  return eventData;
};

export const linkHelper = ({
  pathname,
  accountSlug,
  href,
  locale,
}: {
  pathname: string;
  accountSlug: string;
  href: string;
  locale: string;
}) => {
  // Get the pathname without the locale (/fi/ or /sv/)
  const pathnameWithoutLocale = pathname.replace(`/${locale}`, '');
  // Construct the link href with accountSlug and the link href
  const linkHrefRaw = `/site/${accountSlug}${href?.startsWith('/') ? '' : '/'}${href}`;
  // Remove trailing slash from the link href
  const linkHrefClean = linkHrefRaw.endsWith('/') ? linkHrefRaw.slice(0, -1) : linkHrefRaw;
  // In custom domains, /site/{accountSlug} is not needed, decide which one to use
  const linkHref = pathnameWithoutLocale.includes('/site/') ? linkHrefClean : href;
  // Check if the link is active (fallback to '/' if custom domain & home page)
  const isActive = (pathnameWithoutLocale || '/') === linkHref;
  return { linkHref, isActive };
};

// The following functions are related to keyboard navigation and focus management (ARIA)
// This is a bit more complex than you might expect, because we need to handle varying content blocks

type FocusableElement =
  | HTMLInputElement
  | HTMLButtonElement
  | HTMLLinkElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

const isNotHiddenElement = (element: HTMLElement) => {
  // Check if the element is hidden via CSS (display: none or visibility: hidden)
  if (window.getComputedStyle(element).display === 'none' || window.getComputedStyle(element).visibility === 'hidden') {
    return false;
  }

  // Additional check for aria-hidden attribute
  if (element.getAttribute('aria-hidden') === 'true') {
    return false;
  }
  return true;
};

const isFocusableElement = (element: HTMLElement) => {
  // Check if the element is inherently focusable or has been made focusable
  const focusableElements = ['a', 'button', 'input', 'textarea', 'select', 'details', '[tabindex]'];
  const selector = focusableElements.join(', ');
  if (!element.matches(selector)) {
    return false;
  }

  // Check if the element has a negative tabindex, which makes it programmatically focusable but not keyboard focusable
  if (element.tabIndex < 0) {
    return false;
  }

  // Check if the element is disabled (applies to form elements)
  if ((element as FocusableElement).disabled) {
    return false;
  }

  // Check if the element is hidden
  if (!isNotHiddenElement(element)) {
    return false;
  }

  return true;
};

export const findFirstFocusableElement = (parentElement: HTMLElement) => {
  const allDescendants = parentElement.querySelectorAll('*');
  for (const element of allDescendants) {
    if (isFocusableElement(element as HTMLElement)) {
      return element;
    }
  }
  return null;
};

const findNextVisibleSibling = (startElement: HTMLElement | null) => {
  if (!startElement) return null;
  let currentElement = startElement.nextElementSibling;
  while (currentElement) {
    if (isNotHiddenElement(currentElement as HTMLElement)) {
      return currentElement;
    }
    currentElement = currentElement.nextElementSibling;
  }
  return null;
};

export const focusNextElement = (e: React.MouseEvent<HTMLButtonElement>) => {
  const nextElParent = findNextVisibleSibling((e.target as HTMLButtonElement).parentElement);
  if (!nextElParent) return;
  (findFirstFocusableElement(nextElParent as HTMLElement) as FocusableElement)?.focus();
};
