'use client';

import { ComponentConfig } from '@measured/puck';

import { trpc } from '@/app/_trpc/client';
import { OrderSelect } from '@/app/common/components/order-select';
import { YearSelect } from '@/app/common/components/year-select';
import { CategoryAllEvents } from './category-all-events';
import { useCurrentLocale } from '@/app/(library-site)/locales/client';

export type CategoryAllEventsBlockProps = {
  title: string;
  titles?: {
    code: string;
    name: string;
  }[];
  category?: {
    id: string;
    name: string;
  };
  numberOfEventsPerPage: number;
};

export const CategoryAllEventsBlock = (accountSlug: string): ComponentConfig<CategoryAllEventsBlockProps> => {
  const locale = useCurrentLocale();
  const utils = trpc.useUtils();
  return {
    fields: {
      title: { type: 'text', label: 'Title (defaults to category title)' },
      titles: {
        type: 'array',
        label: 'Titles (multilanguage)',
        arrayFields: {
          code: {
            type: 'select',
            label: 'Language',
            options: [
              // TODO: get from database
              { label: 'Finnish', value: 'fi' },
              { label: 'Swedish', value: 'sv' },
            ],
          },
          name: { type: 'text', label: 'Title' },
        },
      },
      numberOfEventsPerPage: {
        type: 'number',
        label: 'Number of events to show',
      },
      category: {
        type: 'external',
        placeholder: 'Select category',
        fetchList: async () => {
          const categories = await utils.library.getCategories.fetch({ accountSlug });
          return categories.map((category) => ({
            name: category.name,
            id: category.id,
          }));
        },
        label: 'Category',
        getItemSummary: (item) => item?.name || '',
      },
    },
    /* Change the title when the category is changed */
    resolveData: async ({ props }, { changed }) => {
      if (!props.category) return { props };
      if (!changed.category) return { props };

      return {
        props: {
          title: props.category.name.split(' / ').slice(-1)[0],
        },
      };
    },
    defaultProps: {
      title: '',
      numberOfEventsPerPage: 16,
    },
    render: ({ title, titles, category, numberOfEventsPerPage = 16 }) => {
      const years = trpc.library.getAllAccountEventYears.useQuery(
        { accountSlug },
        {
          staleTime: 1000 * 60 * 60 * 24,
        },
      );
      const correctTitle = (titles?.find((t) => t.code === locale) || titles?.[0])?.name || title;
      return (
        <div className="flex items-center justify-center px-5 pb-20 pt-7 lg:px-12 lg:pt-20">
          <div className="flex max-w-[100rem] flex-1 flex-col">
            <div className="flex flex-col justify-between gap-4 lg:mb-5 lg:flex-row lg:items-center lg:gap-0 lg:border-b lg:pb-8">
              <h3 className="font-condensed text-3xl font-semibold lg:text-4xl">{correctTitle || 'Category Title'}</h3>
              <div className="-ml-3 flex items-center gap-2 lg:ml-0">
                {years.data && <YearSelect years={years.data} />}
                <OrderSelect />
              </div>
            </div>
            {category && (
              <CategoryAllEvents
                accountSlug={accountSlug}
                categoryId={category.id}
                numberOfEventsPerPage={numberOfEventsPerPage}
              />
            )}
          </div>
        </div>
      );
    },
  };
};
