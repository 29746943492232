'use client';

import { trpc } from '@/app/_trpc/client';
import { Pagination } from './pagination';
import { CategoryItem } from '../category-events/category-item';
import { useI18n } from '@/app/(library-site)/locales/client';
import { useOrderFilter, usePageFilter, useYearFilter } from '@/app/_hooks/search-params';

const Skeleton = () => (
  <div className="flex w-full animate-pulse flex-wrap justify-center gap-8">
    {[...Array(16)].map((_, i) => (
      <div
        key={i}
        className="bg-card flex aspect-video max-w-[720px] basis-full flex-col lg:basis-[calc(25%-1.5rem)]"
      />
    ))}
  </div>
);

export const CategoryAllEvents = ({
  accountSlug,
  categoryId,
  numberOfEventsPerPage,
}: {
  accountSlug: string;
  categoryId: string;
  numberOfEventsPerPage: number;
}) => {
  const t = useI18n();
  const numOfEventsPerPage = typeof window !== 'undefined' && window.innerWidth <= 1024 ? 5 : numberOfEventsPerPage;
  const pageNum = usePageFilter();
  const orderFilter = useOrderFilter();
  const yearFilter = useYearFilter();

  const getEvents = trpc.library.getCategoryEvents.useQuery(
    {
      categoryId,
      limit: numOfEventsPerPage,
      page: pageNum,
      orderBy: orderFilter,
      year: yearFilter === 'all' ? undefined : parseInt(yearFilter),
    },
    {
      retry(failureCount, error) {
        if (error.data?.httpStatus === 404 || error.data?.httpStatus === 400) {
          return false;
        }
        return failureCount < 2;
      },
    },
  );
  const totalPages = Math.ceil((getEvents.data?.total || 0) / numOfEventsPerPage);

  return (
    <div>
      <div className="flex flex-col items-center gap-8 lg:flex-row lg:flex-wrap lg:items-stretch">
        {getEvents.isLoading && <Skeleton />}
        {getEvents.error && (
          <div className="flex min-h-[200px] w-full items-center justify-center">
            <p className="text-lg">{t('loading.error')}</p>
          </div>
        )}
        {!getEvents.isLoading && getEvents.data?.events?.length === 0 && (
          <div className="flex min-h-[200px] w-full items-center justify-center">
            <p className="text-lg">{t('list.no.results')}</p>
          </div>
        )}
        {getEvents.data?.events?.map((event) => (
          /* Flex basis is calculated by 25% of the container width minus the gap between the items:
        3 * 2rem / 4 = 1.5rem (3 x 2rem gaps per 4 items) */
          <div className="flex flex-col lg:basis-[calc(25%-1.5rem)]" key={event.id}>
            <CategoryItem event={event} accountSlug={accountSlug} />
          </div>
        ))}
      </div>
      {totalPages > 1 && (
        <div className="mt-8 flex items-center justify-center gap-2">
          <Pagination totalPages={totalPages} currentPage={pageNum} />
        </div>
      )}
    </div>
  );
};
